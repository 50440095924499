import { createSharedComposable } from "@vueuse/core";

export const useShopwareExtension = createSharedComposable(() => {
  const config = useRuntimeConfig();

  const shopwareUrl = config.public.shopware?.shopwareEndpoint;
  const extensionUrls = {
    "mega-menu": `${shopwareUrl?.replace("sw/store-api", "api")?.replace("store-api", "api")}/mega-menu`,
    "instagram-photos": `${shopwareUrl}/cr/instagram-feed/photos`,
    product: `${shopwareUrl}/product`,
    "repeat-order": `${shopwareUrl}/checkout/cart/line-item`,
    cart: `${shopwareUrl}/checkout/cart`,
    "greetings-card": `${shopwareUrl}/greetings-card/get-cards`,
    "product-extension": `${shopwareUrl}/crehler-extension/product`,
  } as const;

  const getExtensionUrl = (extensionName: keyof typeof extensionUrls) => {
    return extensionUrls[extensionName];
  };

  const fetchExtension = async (
    url: string,
    options: Record<string, unknown> = {},
  ) => {
    return await useFetch(url, {
      ...options,
      headers: {
        "sw-access-key": config.public.shopware?.shopwareAccessToken || "",
      },
    });
  };

  return {
    getExtensionUrl,
    fetchExtension,
  };
});
